<div class="footer">
  <section class="carousel">
    <div class="carousel-logos">
      <div class="logos-slide">
        <!-- <img ngSrc="assets/images/logos/Baseball.svg" width="400" height="200"/>
        <img ngSrc="assets/images/logos/Cupra.svg" width="400" height="200"/>
        <img ngSrc="assets/images/logos/Infiniti.svg" width="400" height="200"/>
        <img ngSrc="assets/images/logos/ohlins.svg" width="400" height="200"/>
        <img ngSrc="assets/images/logos/sabelt.svg" width="400" height="200"/>
        <img ngSrc="assets/images/logos/bmw.svg" width="400" height="200"/>
        <img ngSrc="assets/images/logos/continental.svg" width="400" height="200"/>
        <img ngSrc="assets/images/logos/mitsubishi.svg" width="400" height="200"/> -->
        <p>Vuoi Sponsorizzarci?</p>
        <p>Contattaci su <span [innerHTML]="email"></span></p>
      </div>
    </div>
  </section>

  <!-- <div class="made-with-love">
    Fatto con il
    <i id="heart">💖</i> & <i id="coffee">☕</i> da
    <a href="https://www.instagram.com/fabfaita99/" target="_blank"
      >Faitanini Fabio</a
    >
  </div> -->

  <div class="copyright text-center text-gray-400 pb-1">
    <p>
      &copy; 2025 San Marino Drift Team. <br />
      Tutti i diritti riservati.
    </p>
  </div>
</div>
