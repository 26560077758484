import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PublicModulesFunction } from '../../function/public-modules-function';

@Component({
    selector: 'app-footer',
    imports: [...PublicModulesFunction, MatIconModule],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  isBrowser = false;
  email = 'info@smdriftteam.com';

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      var copy = document.querySelector('.logos-slide')!.cloneNode(true);
      document.querySelector('.carousel-logos')!.appendChild(copy);
    }
  }
}
